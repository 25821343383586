<template>
  <div>
    <div class="d-none d-md-flex justify-content-end bg-hero-big">
      <div class=" align-self-center text-center px-4" :style="{width: '35%', maxWidth: '500px'}">
        <h3 class="font-didot px-2 fw-5">Mirko Organic and Eco-friendly Sanitary Pads</h3>
        <div class="px-2 pt-5">Breathable, hypoallergenic and safe for you and the environment!</div>
      </div>
    </div>
    <div class="d-md-none">
      <div class="position-relative">
        <img class="" :src="backend + '/photos/learn-pads-hands.jpg'" alt="" :style="{width:'100%', height: '110vw', objectFit: 'cover', objectPosition: 'center'}">
      </div>
      <div class="pb-5">
        <div class="text-center font-didot fs-2 px-4 fw-5">Mirko Organic and Eco-friendly Sanitary Pads</div>
        <div class="text-center px-3 pt-4">Breathable, hypoallergenic and safe for you and the environment!</div>
      </div>
      <div>
        <div class="bg-dpeach2 bottom-0">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750.000000 110.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,110.000000) scale(0.100000,-0.100000)" fill="white" stroke="none">
              <path d="M0 1067 c0 -29 6 -36 78 -78 401 -241 915 -459 1387 -588 512 -140 1032 -176 1457 -100 178 31 312 73 608 189 712 279 1324 438 2010 524 285 36 509 49 825 49 378 -1 713 -29 1014 -84 58 -10 108 -19 113 -19 4 0 8 32 8 70 l0 70 -3750 0 -3750 0 0 -33z"/>
            </g>
          </svg>
        </div>
      </div>
    </div>
    
    
    <div class="bg-dpeach2 position-relative pt-md-5">
      <div :style="{maxWidth: '900px'}" class="mx-auto">
        <div class="row g-0 bg-girl" :style="{backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom right', backgroundSize: 'contain', minHeight: '500px',}">
          <div class="col-6 col-sm-7 col-md-8 d-flex">
            <div class="font-didot fs-5 fw-6 text-start ps-2 pe-3 text-sm-center ps-sm-3 align-self-center w-100 d-md-none" :style="{paddingTop: '100px'}">
              Why switch to Mirko's Sanitary Napkins?
            </div>
            <div class=" d-none d-md-flex px-4 py-5">
              <div class="align-self-center">
                <div class="text-center font-didot fs-4 pb-4">Why switch to Mirko's Sanitary Napkins?</div>
                <div class="d-flex my-4">
                  <div class="align-self-center" :style="{maxWidth: '50px'}"><img :src="backend + '/photos/learn-pads-icon1.png'" alt="" class="img-fluid"></div>
                  <div class="align-self-center ps-3">Certified Organic Cotton Top Sheet</div>
                </div>
                <div class="d-flex my-4">
                  <div class="align-self-center" :style="{maxWidth: '50px'}"><img :src="backend + '/photos/learn-pads-icon2.png'" alt="" class="img-fluid"></div>
                  <div class="align-self-center ps-3">Good for sensitive skin. The use of natural cotton helps prevent irritation, rashes, itching and allergies.</div>
                </div>
                <div class="d-flex my-4">
                  <div class="align-self-center" :style="{maxWidth: '50px'}"><img :src="backend + '/photos/learn-pads-icon3.png'" alt="" class="img-fluid"></div>
                  <div class="align-self-center ps-3">Designed to control leaks and odor to keep you feeling fresh and free all day long.</div>
                </div>
                <div class="d-flex my-4">
                  <div class="align-self-center" :style="{maxWidth: '50px'}"><img :src="backend + '/photos/learn-pads-icon4.png'" alt="" class="img-fluid"></div>
                  <div class="align-self-center ps-3">Made with biodegradable materials. Not only is it good for your body, it's also good for mother earth!</div>
                </div>

              </div>
              
            </div>
          </div>
        </div>  
      </div>
      

      <div class="d-md-none wave-wrapper" :style="{bottom: '-1px'}">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750.000000 107.000000" preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,107.000000) scale(0.100000,-0.100000)" fill="white" stroke="none">
            <path d="M6055 1063 c-360 -21 -588 -47 -912 -103 -536 -93 -1055 -244 -1643 -477 -263 -104 -401 -146 -590 -179 -556 -96 -1211 -9 -1916 254 -315 118 -738 320 -953 457 l-41 25 0 -520 0 -520 3750 0 3750 0 0 479 c0 448 -1 480 -17 485 -98 28 -448 73 -703 91 -179 13 -577 17 -725 8z"/>
          </g>
        </svg>
      </div>
    </div>
    
    <div class="d-md-none pt-3 px-4 pb-5">
      <div class="d-flex my-4">
        <div class="align-self-center" :style="{maxWidth: '50px'}"><img :src="backend + '/photos/learn-pads-icon1.png'" alt="" class="img-fluid"></div>
        <div class="align-self-center ps-3">Certified Organic Cotton Top Sheet</div>
      </div>
      <div class="d-flex my-4">
        <div class="align-self-center" :style="{maxWidth: '50px'}"><img :src="backend + '/photos/learn-pads-icon2.png'" alt="" class="img-fluid"></div>
        <div class="align-self-center ps-3">Good for sensitive skin. The use of natural cotton helps prevent irritation, rashes, itching and allergies.</div>
      </div>
      <div class="d-flex my-4">
        <div class="align-self-center" :style="{maxWidth: '50px'}"><img :src="backend + '/photos/learn-pads-icon3.png'" alt="" class="img-fluid"></div>
        <div class="align-self-center ps-3">Designed to control leaks and odor to keep you feeling fresh and free all day long.</div>
      </div>
      <div class="d-flex my-4">
        <div class="align-self-center" :style="{maxWidth: '50px'}"><img :src="backend + '/photos/learn-pads-icon4.png'" alt="" class="img-fluid"></div>
        <div class="align-self-center ps-3">Made with biodegradable materials. Not only is it good for your body, it's also good for mother earth!</div>
      </div>
      <br><br>
    </div>


    <!-- photo showcase-->
    <div class="row g-0">
      <div class="col-12 col-md-6 col-xl-4 position-relative">
        <img :src="backend + '/photos/learn-pads-closeup.jpg'" class="img-fluid" alt="">
        <div class="wave-wrapper d-md-none" :style="{bottom: '-1px'}">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750.000000 107.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,107.000000) scale(0.100000,-0.100000)" fill="#f6d6c3" stroke="none">
              <path d="M6055 1063 c-360 -21 -588 -47 -912 -103 -536 -93 -1055 -244 -1643 -477 -263 -104 -401 -146 -590 -179 -556 -96 -1211 -9 -1916 254 -315 118 -738 320 -953 457 l-41 25 0 -520 0 -520 3750 0 3750 0 0 479 c0 448 -1 480 -17 485 -98 28 -448 73 -703 91 -179 13 -577 17 -725 8z"/>
            </g>
          </svg>
        </div>
      </div>
      <div class="col-12 col-md-6 col-xl-4 d-none d-md-block photo-showcase">
      </div>

      <div class="d-none d-xl-flex col col-xl-4 justify-content-center">
        <div class="px-4 align-self-center">
          <div :style="{maxWidth: '100px'}" class="mx-auto">
            <img :src="backend + '/photos/learn-pads-icon5.png'" alt="" class="img-fluid">
          </div>
          <div class="text-center lh-lg mt-1">
            <div>Decomposes within 3 years after use. </div><div>
              Made with corn PLA back sheet film Individual wrapper</div>
          </div>
          
          <div :style="{maxWidth: '100px'}" class="mx-auto mt-4">
            <img :src="backend + '/photos/learn-pads-icon6.png'" alt="" class="img-fluid">
          </div>
          <div class="text-center lh-lg mt-1">
            <div>No Harmful Chemicals</div>
            <div>No Chlorine</div>
            <div>No Pesticides</div>
            <div>No Fragrances</div>
          </div>
        </div>
      </div>
      
      
      <div class="col-12 pt-5 bg-dpeach2 d-lg-none">
        <div :style="{maxWidth: '100px'}" class="mx-auto"><img :src="backend + '/photos/learn-pads-icon5.png'" alt="" class="img-fluid"></div>
        <div class="text-center lh-lg mt-4 px-4">
          <div>Decomposes within 3 years after use.</div>
          <div>Made with corn PLA back sheet film and individual wrapper</div>
        </div>       
      </div>

      <div class="col-12 py-5 bg-dpeach2 d-lg-none">
        <div :style="{maxWidth: '100px'}" class="mx-auto"><img :src="backend + '/photos/learn-pads-icon6.png'" alt="" class="img-fluid"></div>
        <div class="text-center lh-lg mt-4">
          <div>No Harmful Chemicals</div>
          <div>No Chlorine</div>
          <div>No Pesticides</div>
          <div>No Fragrances</div>
        </div>
      </div>

      <div class="col-6 my-5 d-none d-lg-block d-xl-none">
        <div :style="{maxWidth: '100px'}" class="mx-auto"><img :src="backend + '/photos/learn-pads-icon5.png'" alt="" class="img-fluid"></div>
        <div class="text-center lh-lg mt-4">
          <div>Decomposes within 3 years after use</div>
          <div>Made with corn PLA back sheet film</div>
          <div>Individual wrapper</div>
        </div>
      </div>
      <div class="col-6 my-5 d-none d-lg-block d-xl-none">
        <div :style="{maxWidth: '100px'}" class="mx-auto"><img :src="backend + '/photos/learn-pads-icon6.png'" alt="" class="img-fluid"></div>
        <div class="text-center lh-lg mt-4">
          <div>No Harmful Chemicals</div>
          <div>No Chlorine</div>
          <div>No Pesticides</div>
          <div>No Fragrances</div>
        </div>
      </div>
    </div>

    
    <!-- shop -->
    <div class="bg-dpeach2">
      <div class="row g-0">
        <div class="d-none d-md-block col-12 bg-dpeach3 py-5">
          <div class="text-center fs-2 text-white">
            NOT YOUR ORDINARY PAD
          </div>
          <div class="text-center pt-3 text-white fw-7">
            Mirko pads offer an effective solution to periods and plastic waste.
          </div>
          
        </div>
      </div>
      <div class="row g-0 mx-auto" :style="{maxWidth: '1200px'}">
        <div class="col-12 col-lg-6  position-relative bg-dpeach2 text-center">
          <div class="mx-auto" :style="{maxWidth: '766px'}">
            <img class="img-fluid p-md-5" :src="backend + '/photos/learn-pads-box.png'" alt="" />
          </div>
          

          <div class="wave-wrapper top-0 d-md-none" :style="{transform: 'scale(-1)'}">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750.000000 107.000000" preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,107.000000) scale(0.100000,-0.100000)" fill="#f6d6c3" stroke="none">
                <path d="M6055 1063 c-360 -21 -588 -47 -912 -103 -536 -93 -1055 -244 -1643 -477 -263 -104 -401 -146 -590 -179 -556 -96 -1211 -9 -1916 254 -315 118 -738 320 -953 457 l-41 25 0 -520 0 -520 3750 0 3750 0 0 479 c0 448 -1 480 -17 485 -98 28 -448 73 -703 91 -179 13 -577 17 -725 8z"/>
              </g>
            </svg>
          </div>
          
          <div class="wave-wrapper bottom-0 d-md-none" >
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 750.000000 107.000000" preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,107.000000) scale(0.100000,-0.100000)" fill="#f6d6c3" stroke="none">
                <path d="M6055 1063 c-360 -21 -588 -47 -912 -103 -536 -93 -1055 -244 -1643 -477 -263 -104 -401 -146 -590 -179 -556 -96 -1211 -9 -1916 254 -315 118 -738 320 -953 457 l-41 25 0 -520 0 -520 3750 0 3750 0 0 479 c0 448 -1 480 -17 485 -98 28 -448 73 -703 91 -179 13 -577 17 -725 8z"/>
              </g>
            </svg>
          </div>
                    
        </div>
        <div class="col-12 col-lg-6 bg-dpeach2 text-center d-flex justify-content-center">
          <div class="align-self-center w-100" :style="{maxWidth: '400px'}">
            <div class="py-5 px-4">Mirko Organic Day Pads are available in packs of 8 and 16.</div>
            <router-link class="btn bg-green fw-5 px-3" to="/product/13">Shop Organic Pads</router-link >
            <div class="py-5 px-4">
              Feel more secure and confident by pairing up the organic pads with our classic period underwear!
            </div>
            <router-link class="btn bg-green fw-5 px-3" to="/product/1">Shop Period Underwear</router-link>
            <br><br><br><br>
          </div>
          
        </div>

      </div>

      
    </div>
  </div>
  
    

  

</template>

<script>


export default {
  name: 'thanks',
  props:{
    backend: {}
  },
  data(){
    return{
      
    }
  },
  methods:{
    add8pack(){
      this.$emit('cartinput',[{"Pack Size":"8"},1,145,"OP00000000-8PK","Organic Sanitary Pads","13"])
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.wave-wrapper{
  position: absolute;
  left: 0;
  right: 0;
}

.bg-girl{
  background-image: url("https://mirkophp.navitag.net/photos/learn-pads-girl.png");
}

@media only screen and (min-width: 600px) {

  .bg-girl{
  background-image: url("https://mirkophp.navitag.net/photos/learn-pads-girl-full.png");
}


}


.bg-hero-big{
  background-image: url("https://mirkophp.navitag.net/photos/learn-pads-showcase.jpg");
  background-position: top;
  background-size: cover;
  height: 60vw;
}


.photo-showcase{
  background-image: url('https://mirkophp.navitag.net/photos/learn-pads-hands.jpg');
  background-size: cover;
  background-position: center;
}






</style>
